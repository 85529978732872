<template>
  <div class="my-comment">
    <van-sticky>
      <div class="query">
        <input type="text" placeholder="请输入需要搜索的内容" v-model="name">
        <button @click="selectComment">搜索</button>
        <img src="~img/25.png">
      </div>
    </van-sticky>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <ul>
        <li v-for="item in commentList" :key="item.id">
          <div class="left">
            <img :src="item.user.avatar" alt="">
          </div>
          <div class="right">
            <div class="top">
              <p class="name">{{item.user.name}}</p>
              <p class="time">{{item.created_at}}</p>
            </div>
            <p class="dec">{{item.comment}}</p>
            <div class="img_box">
              <div class="imgs" v-for="(val,index) in item.file" :key="index" @click="preview(item.type,item.file)">
                <img :src="val" v-if="item.type === 1">
                <video v-else class="video" :src="val" :poster="item.file_image[index]" controls></video>
                <div class="paly-box" v-if="item.type === 2 && isAndroid" @click="playvideo(val)"></div>
              </div>
            </div>
            <div class="link-box">
              <router-link :to="{path:'/activityDetails',query:{id:item.activity.id}}">
                <p>{{item.activity.name}} > </p>
              </router-link>
            </div>
          </div>
          <button @click="show = true;commentId = item.id">删除评论</button>
        </li>
      </ul>
    </van-list>
    <tabbar />
    <van-overlay :show="show">
      <div class="delete">
        <div class="tops">确定要删除这条评论吗？</div>
        <div class="btn">
          <div class="close" @click="show = false">取消</div>
          <div class="comfrm" @click="deleteComment">确定</div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showVideo" @click="closeVideo">
      <van-icon name="close" color="#fff" @click="closeVideo" />
      <video class="video-box" ref="video" :src="videoUrl" autoplay controls></video>
    </van-overlay>
  </div>
</template>


<script>
import tabbar from "../../components/Tabbar.vue";
import { ImagePreview } from "vant";

export default {
  components: {
    tabbar,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      show: false,
      loading: false,
      finished: false,
      limit: 5,
      page: 0,
      name: "",
      commentList: [],
      commentId: "",
      isAndroid: false,
      showVideo: false,
      videoUrl: "",
    };
  },
  created() {
    let u = navigator.userAgent;
    this.isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; // android终端
  },
  methods: {
    onLoad() {
      this.page++;
      this.getMyComment();
    },
    async getMyComment() {
      const res = await this.axios.get(
        `user/comment?limit=${this.limit}&page=${this.page}&name=${this.name}`
      );
      console.log(res);
      if (res.code === 200) {
        const { list, meta } = res.data;
        list.forEach((item) => {
          this.commentList.push(item);
        });
        this.loading = false;
        if (this.commentList.length >= meta.total) this.finished = true;
        console.log(res);
      }
    },
    async deleteComment() {
      const res = await this.axios.delete(`comment/${this.commentId}`);
      if (res.code === 200) {
        this.page = 1;
        this.commentList = [];
        this.finished = false;
        this.getMyComment();
        this.show = false;
      }
    },
    selectComment() {
      this.page = 1;
      this.commentList = [];
      this.loading = false;
      this.finished = false;
      this.getMyComment();
    },
    preview(type, list) {
      if (type === 1) {
        ImagePreview(list);
      }
    },
    playvideo(val) {
      if (this.isAndroid) {
        this.showVideo = true;
        this.videoUrl = val;
        console.log(this.$refs.video);
      }
    },
    closeVideo() {
      if (this.isAndroid) {
        this.$refs.video.pause();
        this.showVideo = false;
      }
    },
  },
  beforeDestory() {
    if (this.$refs.video) {
      this.$refs.video.pause();
    }
  },
};
</script>


<style lang="less" scoped>
.my-comment {
  .query {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-top: 31px;
    background: #f8f8f8;
    img {
      width: 31px;
      left: 55px;
      top: 65%;
      transform: translate(0, -50%);
      position: absolute;
    }
    button {
      font-size: 27px;
      color: #fff;
      width: 89px;
      height: 72px;
      border-radius: 10px;
      background: url("../../assets/img/24.png") no-repeat;
      background-size: contain;
      margin-right: 30px;
    }
    input {
      width: 586px;
      height: 72px;
      font-size: 27px;
      background: #fff;
      border-radius: 10px;
      padding-left: 80px;
      margin-left: 30px;
    }
    ::-webkit-input-placeholder {
      /* WebKit browsers，webkit内核浏览器 */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10+ */
      color: #a4a4a4;
      font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    }
  }
  ul {
    padding-top: 30px;
    li {
      width: 92%;
      margin: auto;
      background: #fff;
      border-radius: 18px;
      display: flex;
      padding: 30px 26px 20px 30px;
      position: relative;
      margin-bottom: 30px;
      .left {
        img {
          width: 60px;
          height: 60px;
          border-radius: 10px;
        }
      }
      .right {
        padding-left: 20px;
        padding-top: 10px;
        .top {
          .name {
            font-size: 26px;
          }
          .time {
            font-size: 22px;
            color: #999999;
          }
        }
        .dec {
          font-size: 26px;
          color: #727272;
          line-height: 38px;
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .img_box {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-column-gap: 22px;
          grid-row-gap: 22px;
          .imgs {
            width: 170px;
            height: 170px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            border-radius: 10px;
            position: relative;
            img {
              height: 184px;
              width: auto;
            }
          }
        }
        /* 隐藏video 所有控件 */
        .video::-webkit-media-controls-enclosure {
          display: none;
        }
        .paly-box {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
        }
        .link-box {
          font-size: 24px;
          color: #0094ff;
          margin-top: 20px;
          a {
            color: #0094ff;
          }
        }
      }
      button {
        width: 130px;
        height: 44px;
        background: #f26a52;
        position: absolute;
        font-size: 24px;
        color: #fff;
        top: 36px;
        right: 26px;
        border-radius: 5px;
      }
    }
  }
  .delete {
    width: 510px;
    height: 254px;
    background: transparent;
    border-radius: 18px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    .tops {
      height: 175px;
      font-size: 30px;
      line-height: 175px;
      text-align: center;
      background: #fff;
    }
    .btn {
      height: 79px;
      display: flex;
      font-size: 27px;
      color: #fff;
      text-align: center;
      line-height: 79px;
      .close {
        width: 50%;
        height: 100%;
        background: #b4b4b4;
      }
      .comfrm {
        width: 50%;
        background: url("../../assets/img/46.png") no-repeat;
        background-size: cover;
      }
    }
  }
  .van-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  .video-box {
    width: 100%;
  }
  .van-icon-close {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 999;
  }
}
</style>